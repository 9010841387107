import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1827cf4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = {
  type: "button",
  class: "btn btn-light-primary me-3",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = { class: "svg-icon svg-icon-2" }
const _hoisted_12 = { class: "card-body pt-0" }
const _hoisted_13 = {
  key: 0,
  class: "fs-7 text-gray-400"
}
const _hoisted_14 = { class: "mw-90px" }
const _hoisted_15 = { class: "mw-90px" }
const _hoisted_16 = { class: "mw-125px" }
const _hoisted_17 = { class: "mw-350px pre-wrap" }
const _hoisted_18 = { class: "text-truncate fs-7 text-gray-400" }
const _hoisted_19 = { class: "d-flex flex-row flex-nowrap justify-content-end align-items-center" }
const _hoisted_20 = { class: "svg-icon svg-icon-3" }
const _hoisted_21 = ["title", "onClick"]
const _hoisted_22 = { class: "svg-icon svg-icon-3" }
const _hoisted_23 = ["title", "onClick"]
const _hoisted_24 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_FilterDropdown = _resolveComponent("FilterDropdown")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBDatatable = _resolveComponent("MBDatatable")!
  const _component_AddPaymentModal = _resolveComponent("AddPaymentModal")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen021.svg" })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
              class: "form-control form-control-solid w-250px ps-14",
              placeholder: _ctx.t('common.search')
            }, null, 40, _hoisted_6), [
              [_vModelText, _ctx.search]
            ]),
            _withDirectives(_createElementVNode("span", {
              class: "svg-icon svg-icon-1 position-absolute top-50 end-0 mr-2 translate-middle",
              style: {"cursor":"pointer"},
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeSearch && _ctx.removeSearch(...args)))
            }, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ], 512), [
              [_vShow, _ctx.search != '']
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen031.svg" })
              ]),
              _createTextVNode(_toDisplayString(_ctx.t("common.filter")), 1)
            ]),
            _createVNode(_component_FilterDropdown, {
              onSubmitFilter: _ctx.handleFilter,
              onResetFilter: _ctx.handleFilterReset
            }, null, 8, ["onSubmitFilter", "onResetFilter"]),
            _withDirectives((_openBlock(), _createElementBlock("button", {
              type: "button",
              class: "btn btn-primary me-3",
              "data-bs-toggle": "modal",
              "data-bs-target": "#modal_add_payment",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onAddButtonClick && _ctx.onAddButtonClick(...args)))
            }, [
              _createElementVNode("span", _hoisted_11, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr075.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.t("payment.addPayment")), 1)
            ])), [
              [_directive_auth, { auth: ['add'] }]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_MBDatatable, {
          loading: _ctx.loading,
          "table-data": _ctx.tableData,
          "table-header": _ctx.tableHeader,
          order: "desc",
          "enable-items-per-page-dropdown": true,
          "current-page": _ctx.currentPage,
          total: _ctx.total,
          "rows-per-page": _ctx.pageSize,
          onCurrentChange: _ctx.onCurrentPageChange,
          onItemsPerPageChange: _ctx.onRowsPerPageChange,
          onSort: _ctx.onColumnSort
        }, {
          "cell-payment_order_no": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.payment_order_no) + " ", 1),
            (
              item.reference_order_no &&
              item.reference_order_no != item.payment_order_no
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(item.reference_order_no), 1))
              : _createCommentVNode("", true)
          ]),
          "cell-created_at": _withCtx(({ row: item }) => [
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.formatDateTime(item.created_at)), 1)
          ]),
          "cell-paid_time": _withCtx(({ row: item }) => [
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.formatDateTime(item.paid_time)), 1)
          ]),
          "cell-customer": _withCtx(({ row: item }) => [
            _createElementVNode("div", _hoisted_16, _toDisplayString(item.customer_name), 1)
          ]),
          "cell-description": _withCtx(({ row: item }) => [
            _createElementVNode("div", _hoisted_17, _toDisplayString(item.description), 1)
          ]),
          "cell-amount": _withCtx(({ row: item }) => [
            _createElementVNode("div", {
              class: _normalizeClass(["text-nowrap", {
              'text-info': item.status != 'paid',
            }])
            }, _toDisplayString(_ctx.$currency.currency(item.amount, 2, ",", ".", "‌€ ")), 3),
            _createElementVNode("div", _hoisted_18, _toDisplayString(item.__show.platform), 1)
          ]),
          "cell-status": _withCtx(({ row: item }) => [
            _createElementVNode("span", {
              class: _normalizeClass(["badge", _ctx.getStatusClass(item.status)])
            }, _toDisplayString(item.__show.status), 3)
          ]),
          "cell-actions": _withCtx(({ row: item }) => [
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_router_link, {
                to: '/payment-management/payment/' + item.id + '/overview',
                class: "btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-3"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_20, [
                    _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen004.svg" })
                  ])
                ]),
                _: 2
              }, 1032, ["to"]),
              _createElementVNode("button", {
                class: "btn btn-sm btn-icon btn-light-primary btn-active-color-white me-3",
                title: _ctx.t('payment.copyTip'),
                onClick: ($event: any) => (_ctx.copyText(item.pay_link))
              }, [
                _createElementVNode("span", _hoisted_22, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen054.svg" })
                ])
              ], 8, _hoisted_21),
              (item.status === _ctx.PaymentStatus.Pending)
                ? _withDirectives((_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "btn btn-sm btn-icon btn-light-danger",
                    title: _ctx.t('common.cancel'),
                    onClick: ($event: any) => (_ctx.onCancelButtonClick(item.id))
                  }, [
                    _createElementVNode("span", _hoisted_24, [
                      _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen027.svg" })
                    ])
                  ], 8, _hoisted_23)), [
                    [_directive_auth, { auth: ['update'] }]
                  ])
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange", "onSort"])
      ])
    ]),
    _createVNode(_component_AddPaymentModal, {
      id: _ctx.editId,
      onUpdateList: _ctx.updateList
    }, null, 8, ["id", "onUpdateList"])
  ], 64))
}
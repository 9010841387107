
import { defineComponent, ref, onMounted, computed } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import AddPaymentModal from "./AddPaymentModal.vue";
import FilterDropdown from "./FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiPayment } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  formatDate,
  formatDateTime,
  setModuleBCN,
  commonChangeFilterAddDate,
} from "@/core/directive/function/common";
import {
  PaymentListItem,
  PaymentStatus,
} from "@/core/directive/interface/payment";
import { getPaymentStatus } from "@/core/directive/function/paymentManagement";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import useClipboard from "vue-clipboard3";

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "payment-management-payment",
  components: {
    MBDatatable,
    AddPaymentModal,
    FilterDropdown,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const { toClipboard } = useClipboard();
    const loading = ref(true);
    const tableData = ref<Array<PaymentListItem>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);

    const editId = ref("");
    const disabledExport = ref<boolean>(false);
    const disabledExportLog = ref<boolean>(false);

    const tableHeader = ref([
      // {
      //   name: "ID",
      //   key: "id",
      //   sortable: true,
      // },
      {
        name: t("payment.paymentOrderNo"),
        key: "payment_order_no",
        sortable: true,
      },
      {
        name: t("payment.createdAt"),
        key: "created_at",
        sortable: false,
      },
      {
        name: t("payment.paidTime"),
        key: "paid_time",
        sortable: false,
      },
      {
        name: t("payment.customer"),
        key: "customer",
        sortable: false,
      },
      {
        name: t("payment.description"),
        key: "description",
        sortable: false,
      },
      {
        name: t("payment.amount"),
        key: "amount",
        sortable: true,
        align: "right",
      },
      {
        name: t("payment.status"),
        key: "status",
        sortable: false,
      },
      {
        name: t("common.actions"),
        key: "actions",
        sortable: false,
        align: "right",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getPaymentListData();
    });

    const getPaymentListData = () => {
      loading.value = true;
      ApiPayment.getPaymentList({
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        sort_orders: sortOptions.value,
        filter_group: filterOptions.value,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
            editId.value = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getStatusClass = computed(() => {
      return (status) => {
        return getPaymentStatus(status);
      };
    });

    const copyText = async (text: string) => {
      try {
        await toClipboard(text);
        Swal.fire({
          title: t("payment.copyMsg"),
          icon: "info",
          buttonsStyling: false,
          showCancelButton: false,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } catch (error) {
        console.error("Copy failed:", error);
      }
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] !== "") {
          if (item == "paid_time_start") {
            filterArr.push({
              field: "paid_time",
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (item == "paid_time_end") {
            filterArr.push({
              field: "paid_time",
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else if (
            item == "platform_id" ||
            item == "ocps_customer_id" ||
            item == "status"
          ) {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getPaymentListData();
    };

    const handleFilterReset = () => {
      resetFilter();
      getPaymentListData();
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getPaymentListData();
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getPaymentListData();
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getPaymentListData();
    };

    const debounceSearch = _.debounce(getPaymentListData, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch();
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const updateList = () => {
      getPaymentListData();
    };

    const onAddButtonClick = () => {
      editId.value = "";
    };

    const onEditButtonClick = (id) => {
      editId.value = id;
    };

    const cancelPayment = async (id) => {
      loading.value = true;
      const { data } = await ApiPayment.cancelPayment({
        id: id,
      });
      loading.value = false;
      if (data.code == 0) {
        updateList();
        Swal.fire({
          text: t("payment.cancelSuccessMsg"),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    };

    const onCancelButtonClick = (id) => {
      Swal.fire({
        text: t("payment.cancelConfirmMsg"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.confirm"),
        cancelButtonText: t("common.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          cancelPayment(id);
        }
      });
    };

    return {
      t,
      formatDate,
      formatDateTime,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      editId,
      disabledExport,
      disabledExportLog,
      getPaymentListData,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      updateList,
      onAddButtonClick,
      onEditButtonClick,
      onCancelButtonClick,
      getStatusClass,
      copyText,
      PaymentStatus,
    };
  },
});
